import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { isTokenNearlyExpired, refreshAccessToken } from '@/api/authentication';
import { getStorage } from '@/utils/storage';
import { BrowserStorageKey } from '@/constants/browserStorage';

// it will be created after user logs in as we don't know domain in advance
let axiosInstance: AxiosInstance;
const MAX_REQUEST_RETRIES = 3;

function delay(retryNumber: number) {
  const requestDelay = Math.pow(2, retryNumber) * 500;
  return new Promise((resolve) => setTimeout(resolve, requestDelay));
}

export const createDomainAxiosInstance = (domain: string) => {
  //domain is defined by username. So he first needs to successfully log in to define the domain name .
  axiosInstance = axios.create({
    baseURL: builtDomainUrl(domain),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axiosInstance.interceptors.request.use(requestInterceptor);
  axiosInstance.interceptors.response.use((r) => r, responseErrorInterceptor);

  async function requestInterceptor(request: any) {
    const authorizationHeaders = buildAuthorizationHeader();
    if (isTokenNearlyExpired()) {
      await refreshAccessToken();
    }
    request.headers = { ...request.headers, ...authorizationHeaders };
    return request;
  }

  let refreshTokenRetryCounter = 0;

  /**
   Intercepts responses to handle errors, particularly token expiration.

   This function ensures that we attempt a limited number of retries to refresh
   the access token when a `401 Unauthorized` error occurs due to token expiration.

   - Each request includes an `_retryCount` property to track how many retry
   attempts have been made specifically for that request.

   - We maintain a retry counter (`refreshTokenRetryCounter`) to avoid infinite
   loops of token refresh attempts.
   */
  async function responseErrorInterceptor(error: any) {
    const originalRequest = error.config;
    refreshTokenRetryCounter++;
    if (!originalRequest._retryCount) originalRequest._retryCount = 0;
    if (error.response.status === 401 && originalRequest._retryCount < MAX_REQUEST_RETRIES && refreshTokenRetryCounter < MAX_REQUEST_RETRIES) {
      try {
        await refreshAccessToken();
        refreshTokenRetryCounter = 0;
        originalRequest._retryCount++;
        await delay(originalRequest._retryCount);
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('An error happened while refreshing the token.', refreshError);
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    console.error('Unexpected error occurred.', error);
    return Promise.reject(error);
  }
};

type HttpRequestConfig = Omit<AxiosRequestConfig<any>, 'data'>;

export const getAxiosInstance = () => axiosInstance;

export const httpGet = async <R>(endpoint: string, config?: HttpRequestConfig) => {
  return axiosInstance.get<R>(endpoint, config).then((r) => r.data);
};

export const httpPost = async <D extends object | null, R>(endpoint: string, data?: D, config?: HttpRequestConfig): Promise<R> => {
  const isFormData = data instanceof FormData;
  const headers = {
    ...config?.headers,
    ...(isFormData ? { 'Content-Type': 'multipart/form-data' } : null),
  };
  const finalConfig = { ...config, headers };
  return axiosInstance.post(endpoint, data, finalConfig);
};

export const httpPut = async <D extends object | null, R>(endpoint: string, data?: D, config?: HttpRequestConfig): Promise<R> => {
  const isFormData = data instanceof FormData;
  const headers = {
    ...config?.headers,
    ...(isFormData ? { 'Content-Type': 'multipart/form-data' } : null),
  };
  const finalConfig = { ...config, headers };
  return axiosInstance.put(endpoint, data, finalConfig);
};

export const httpDelete = async <R>(endpoint: string, config?: HttpRequestConfig): Promise<R> => {
  return axiosInstance.delete(endpoint, config);
};

export const buildAuthorizationHeader = () => {
  const storage = getStorage();
  const loginToken = storage.getItem(BrowserStorageKey.LOGIN_TOKEN);
  return { Authorization: 'Bearer ' + loginToken };
};

// todo move to httpUtils
export const builtDomainUrl = (domain: string) => {
  if (import.meta.env.DEV) return import.meta.env.VITE_LOCAL_BASE_URL ?? `https://${domain}/wtgapi`;
  return `https://${domain}/wtgapi`;
};

// todo move to httpUtils
export const buildFormData = (data: any): FormData => {
  const bodyFormData = new FormData();
  for (const key in data) {
    const value = data[key];
    if (value != null) bodyFormData.append(key, value);
    else bodyFormData.append(key, '' as any);
  }
  return bodyFormData;
};
